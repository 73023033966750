/* TODO APP*/
export const GET_LIST = "GET_LIST";
export const GET_LIST_SUCCESS = "GET_LIST_SUCCESS";
export const MARK_ALL_ITEMS = "MARK_ALL_ITEMS";
export const ADD_NEW_ITEM = "ADD_NEW_ITEM";
export const REMOVE_ITEM = "REMOVE_ITEM";
export const SELECTED_ITEM = "SELECTED_ITEM";

/* TODO FIREBASE APP*/
export const GET_LIST_FIREBASE = "GET_LIST";
export const GET_LIST_SUCCESS_TASK = "GET_LIST_SUCCESS_TASK";
export const MARK_ALL_TASK = "MARK_ALL_TASK";
export const ADD_NEW_TASK = "ADD_NEW_TASK";
export const REMOVE_TASK = "REMOVE_TASK";
export const SELECTED_TASK = "SELECTED_TASK";


/* ECOMMERRCE APP */

export const GET_ALL_PRODUCT = "GET_ALL_PRODUCT";
export const SELECTED_PRODUCT = "SELECTED_PRODUCT";
export const CHANGE_CURRENCY = 'CHANGE_CURRENCY'
export const SEARCH_MEMBER_PRODUCT = 'SEARCH_MEMBER_PRODUCT';

// Cart
export const ADD_TO_CART = 'ADD_TO_CART';
export const REMOVE_FROM_CART = 'REMOVE_FROM_CART';
export const INCREMENT_QTY = 'INCREMENT_QTY';
export const DECREMENT_QTY = 'DECREMENT_QTY';
export const GET_SINGLE_ITEM = "GET_SINGLE_ITEM";

// WishList
export const ADD_TO_WISHLIST = 'ADD_TO_WISHLIST';
export const REMOVE_FROM_WISHLIST = 'REMOVE_FROM_WISHLIST';

// CheckOut Process
export const CHECKOUT_REQUEST = 'CHECKOUT_REQUEST';
export const CHECKOUT_SUCCESS = 'CHECKOUT_SUCCESS';
export const CHECKOUT_FAILURE = 'CHECKOUT_FAILURE';

// FILTERS
export const FILTER_BRAND = 'FILTER_BRAND';
export const FILTER_COLOR = 'FILTER_COLOR';
export const FILTER_PRICE = 'FILTER_PRICE';
export const SORT_BY = 'SORT_BY';
export const SEARCH_BY = 'SEARCH_BY';

/* CHAT APP*/
export const GET_MEMBERS = "GET_MEMBERS";
export const GET_MEMBERS_SUCCESS = "GET_MEMBERS_SUCCESS";
export const GET_CHATS = "GET_CHATS";
export const GET_CHATS_SUCCESS = "GET_CHATS_SUCCESS";
export const GET_CHATS_ERROR = "GET_CHATS_ERROR";
export const SEND_MESSAGE = "SEND_MESSAGE";
export const SEARCH_MEMBER = "SEARCH_MEMBER";
export const CHANGE_CHAT = "CHANGE_CHAT";
export const CREATE_CHAT = "CREATE_CHAT";
export const UPDATE_STATUS = "UPDATE_STATUS";
export const UPDATE_SELECTED_USER = "UPDATE_SELECTED_USER";
export const REPLY_BY_SELECTED_USER = "REPLY_BY_SELECTED_USER"

// EMAIL APP
export const GET_EMAILS = "GET_EMAILS";
export const GET_ALL_EMAILS = "GET_ALL_EMAILS";
export const GET_EMAIL_TYPES = "GET_EMAIL_TYPES";
export const UPDATE_EMAIL_TYPES = "UPDATE_EMAIL_TYPES";

// AUTHENTICATION APP
export const LOGIN = "LOGIN";
export const INITIALIZE_FIREBASE = "INITIALIZE_FIREBASE";

//CUSTOMIZER 
export const ADD_COSTOMIZER = "ADD_COSTOMIZER";
export const ADD_LAYOUT = "ADD_LAYOUT";
export const ADD_SIDEBAR_TYPES = "ADD_SIDEBAR_TYPES";
export const ADD_SIDEBAR_SETTINGS = "ADD_SIDEBAR_SETTINGS";
export const ADD_COLOR = "ADD_COLOR";
export const ADD_MIXlAYOUT = "ADD_MIXlAYOUT";


/** TASS WEB */
//FILTERS
export const FILTER_HEADER = 'FILTER_HEADER';

//Authentications
export const SET_AUTHUSER = "SET_AUTHUSER";
export const SET_SIDEBAR_ACTIVE = "SET_SIDEBAR_ACTIVE";
export const FETCH_SAML_STRATEGIES = "FETCH_SAML_STRATEGIES";
export const FETCH_SAML_STRATEGIES_LOADING = "FETCH_SAML_STRATEGIES_LOADING";
export const SAML_USER_DATA = "SAML_USER_DATA";

/** Interim Login **/
export const SET_INTERIM_USER = "SET_INTERIM_USER";
export const REMOVE_INTERIM_USER = "REMOVE_INTERIM_USER";
export const REMOVE_INTERIM_USER_ERROR = "REMOVE_INTERIM_USER_ERROR";
export const REMOVE_INTERIM_USER_LOADING = "REMOVE_INTERIM_USER_LOADING";


/** SIGNUP **/
export const SET_CANVAS_KEYS = "SET_CANVAS_KEYS";
export const SET_CANVAS_KEYS_LOADING = "SET_CANVAS_KEYS_LOADING";
export const VALIDATE_USER_TOKEN = "VALIDATE_USER_TOKEN";
export const VALIDATE_USER_TOKEN_LOADING = "VALIDATE_USER_TOKEN_LOADING";

/** CONSUMER **/
export const FETCH_CONSUMER = "FETCH_CONSUMER";
export const FETCH_CONSUMER_LOADING = "FETCH_CONSUMER_LOADING";
export const FETCH_CONSUMERS = "FETCH_CONSUMERS";
export const FETCH_CONSUMERS_LOADING = "FETCH_CONSUMERS_LOADING";
export const SAVE_CONSUMER = "SAVE_CONSUMER";
export const SAVE_CONSUMER_LOADING = "SAVE_CONSUMER_LOADING";
export const UPDATE_CONSUMER = "UPDATE_CONSUMER";
export const UPDATE_CONSUMER_LOADING = "UPDATE_CONSUMER_LOADING";
export const DELETE_CONSUMER = "DELETE_CONSUMER";
export const DELETE_CONSUMER_LOADING = "DELETE_CONSUMER_LOADING";
export const REGEN_KEY = "REGEN_KEY";
export const REGEN_KEY_LOADING = "REGEN_KEY_LOADING";
export const REGEN_KEY_RESET = "REGEN_KEY_RESET";

/** LicenseManager **/
export const LICENSE_MANAGER_PASSWORD_RESET_LOADING = "LICENSE_MANAGER_PASSWORD_RESET_LOADING";
export const LICENSE_MANAGER_PASSWORD_RESET_EMAIL_LOADING = "LICENSE_MANAGER_PASSWORD_RESET_EMAIL_LOADING";
export const LICENSE_MANAGER_SEND_VERIFICATION_CODE = "LICENSE_MANAGER_SEND_VERIFICATION_CODE";
export const LICENSE_MANAGER_VALIDATE_VERIFICATION_CODE = "LICENSE_MANAGER_VALIDATE_VERIFICATION_CODE";
export const LICENSE_MANAGER_UPDATE_STATUS = "LICENSE_MANAGER_UPDATE_STATUS";

/** Onboarding Signin **/
export const SET_SUB_USER = "SET_SUB_USER";
export const SET_SUB_USER_LOADING = "SET_SUB_USER_LOADING";
export const REMOVE_SUB_USER = "REMOVE_SUB_USER";
export const REMOVE_SUB_USER_LOADING = "REMOVE_SUB_USER_LOADING";

/** INNOVATIVE **/
export const CLEAR_STUDENT_PROFILE_CHART_DATA = "CLEAR_STUDENT_PROFILE_CHART_DATA";
export const FETCH_STUDENT_PROFILE_CHART_DATA = "FETCH_STUDENT_PROFILE_CHART_DATA";
export const FETCH_SETTINGS = "FETCH_SETTINGS";
export const FETCH_SETTINGS_LOADING = "FETCH_SETTINGS_LOADING";
export const FETCH_GLOBAL_SETTINGS = "FETCH_GLOBAL_SETTINGS";
export const FETCH_GLOBAL_SETTINGS_LOADING = "FETCH_GLOBAL_SETTINGS_LOADING";
export const FETCH_IS_ADMIN_LEVEL_USER = "FETCH_IS_ADMIN_LEVEL_USER";
export const FETCH_IS_ADMIN_LEVEL_USER_LOADING = "FETCH_IS_ADMIN_LEVEL_USER_LOADING";
export const SET_SELECTED_ACCOUNT = "SET_SELECTED_ACCOUNT";
export const SAVE_SETTINGS = "SAVE_SETTINGS";
export const SAVE_SETTINGS_LOADING = "SAVE_SETTINGS_LOADING";
export const SAVE_GLOBAL_SETTINGS = "SAVE_GLOBAL_SETTINGS";
export const SAVE_GLOBAL_SETTINGS_LOADING = "SAVE_GLOBAL_SETTINGS_LOADING";
export const FETCH_COURSE = "FETCH_COURSE";
export const CLEAR_COURSE = "CLEAR_COURSE";
export const CLEAR_COURSE_REPORT = "CLEAR_COURSE_REPORT";
export const FETCH_COURSE_LOADING = "FETCH_COURSE_LOADING";
export const CLEAR_COURSES = "CLEAR_COURSES";
export const FETCH_COURSES = "FETCH_COURSES";
export const FETCH_COURSES_LOADING = "FETCH_COURSES_LOADING";
export const FETCH_COURSE_REPORT = "FETCH_COURSE_REPORT";
export const FETCH_COURSE_REPORT_LOADING = "FETCH_COURSE_REPORT_LOADING";
export const FETCH_ASSIGNMENTS = "FETCH_ASSIGNMENTS";
export const FETCH_ASSIGNMENTS_LOADING = "FETCH_ASSIGNMENTS_LOADING";
export const FETCH_ASSIGNMENTS_INFORMATION = "FETCH_ASSIGNMENTS_INFORMATION";
export const FETCH_ASSIGNMENTS_INFORMATION_LOADING = "FETCH_ASSIGNMENTS_INFORMATION_LOADING";
export const FETCH_STUDENT_REPORT = "FETCH_STUDENT_REPORT";
export const SET_STUDENT_COURSE_STATUS = "SET_STUDENT_COURSE_STATUS";
export const RESET_STUDENT_COURSE_STATUS = "RESET_STUDENT_COURSE_STATUS";
export const FETCH_STUDENT_REPORT_LOADING = "FETCH_STUDENT_REPORT_LOADING";
export const FETCH_STUDENT = "FETCH_STUDENT";
export const FETCH_STUDENT_LOADING = "FETCH_STUDENT_LOADING";
export const CLEAR_STUDENT_DATA = "CLEAR_STUDENT_DATA";
export const CLEAR_STUDENT_DETAILS = "CLEAR_STUDENT_DETAILS";
export const FETCH_DUE_ASSIGNMENTS = "FETCH_DUE_ASSIGNMENTS";
export const FETCH_DUE_ASSIGNMENTS_LOADING = "FETCH_DUE_ASSIGNMENTS_LOADING";
export const SET_LAYOUT_BREADCRUMB = "SET_LAYOUT_BREADCRUMB";
export const APPEND_TO_LAYOUT_BREADCRUMB = "APPEND_TO_LAYOUT_BREADCRUMB";
export const REPLACE_LAYOUT_BREADCRUMB_SEGMENT = "REPLACE_LAYOUT_BREADCRUMB_SEGMENT";
export const SET_LAYOUT_QUERY_PARAMS = "SET_LAYOUT_QUERY_PARAMS";
export const SET_TOP_NAV_PARAM = "SET_TOP_NAV_PARAM";
export const SET_INNOVATIVE_SIDEBAR = "SET_INNOVATIVE_SIDEBAR";
export const SET_SELECTED_NAVIGATION_TYPE = "SET_SELECTED_NAVIGATION_TYPE";
export const FETCH_TERMS_LOADING = "FETCH_TERMS_LOADING";
export const FETCH_TERMS = "FETCH_TERMS";
export const FETCH_ADMING_TERMS_LOADING = "FETCH_ADMING_TERMS_LOADING";
export const FETCH_ADMING_TERMS = "FETCH_ADMING_TERMS";
export const CLEAR_TERMS_DATA = "CLEAR_TERMS_DATA";
export const SET_TERM = "SET_TERM";
export const SET_COURSE_FILTER = "SET_COURSE_FILTER";
export const FETCH_STUDENT_TERMS_LOADING = "FETCH_STUDENT_TERMS_LOADING";
export const FETCH_STUDENT_TERMS = "FETCH_STUDENT_TERMS";
export const CLEAR_STUDENT_TERMS_DATA = "CLEAR_STUDENT_TERMS_DATA";
export const FETCHING_ASSIGNMENT_FILTER_DATA = "FETCHING_ASSIGNMENT_FILTER_DATA";
export const FETCHED_ASSIGNMENT_FILTER_DATA = "FETCHED_ASSIGNMENT_FILTER_DATA";
export const SET_SWITCH_FORM_VALUES = "SET_SWITCH_FORM_VALUES";
export const FETCH_COURSE_PROGRESS = "FETCH_COURSE_PROGRESS";
export const UPDATE_FETCH_COURSE_TIME_FILTERS = "UPDATE_FETCH_COURSE_TIME_FILTERS";
export const UPDATE_APPLICATION_LEVEL_ADMIN_USER = "UPDATE_APPLICATION_LEVEL_ADMIN_USER";
export const FETCH_MODULE_ITEM_DATA_LOADING = "FETCH_MODULE_ITEM_DATA_LOADING";
export const FETCH_MODULE_ITEM_DATA = "FETCH_MODULE_ITEM_DATA";
export const RESET_MODULE_PROGRESS_DATA = "RESET_MODULE_PROGRESS_DATA";
export const SEARCH_STUDENT = "SEARCH_STUDENT";
export const SEARCH_STUDENT_LOADING = "SEARCH_STUDENT_LOADING";
export const UPDATE_FILTER_RADIO_VALUE = "UPDATE_FILTER_RADIO_VALUE";
export const RESET_SEARCH_STUDENT_LOADING = "RESET_SEARCH_STUDENT_LOADING";
export const FETCH_STUDENT_ASSIGNMENTS_CHART_DATA = "FETCH_STUDENT_ASSIGNMENTS_CHART_DATA";
export const CLEAR_STUDENT_ASSIGNMENTS_CHART_DATA = "CLEAR_STUDENT_ASSIGNMENTS_CHART_DATA";
export const RESET_FETCH_COURSE_TIME_FILTERS = "RESET_FETCH_COURSE_TIME_FILTERS";
export const FETCH_NEW_COURSE_DATA = "FETCH_NEW_COURSE_DATA";
export const UPDATE_ADVISOR_VIEW_VISIBILITY = "UPDATE_ADVISOR_VIEW_VISIBILITY";
export const FETCH_ADVISOR_VISIBILITY_LOADING = "FETCH_ADVISOR_VISIBILITY_LOADING";
export const SELECTED_ROLE="SELECTED_ROLE";
export const FETCH_ROLE_LIST="FETCH_ROLE_LIST";
export const FETCH_ROLE_LIST_LOADING="FETCH_ROLE_LIST_LOADING";
export const ADVISOR_FILTERS ="ADVISOR_FILTERS";
export const ADVISOR_FILTERS_LOADING = "ADVISOR_FILTERS_LOADING";
export const FETCH_ADVISOR_STUDENT = "FETCH_ADVISOR_STUDENT";
export const ADVISOR_STUDENT_LOADING = "ADVISOR_STUDENT_LOADING";
export const RESET_ADVISOR_STUDENT="RESET_ADVISOR_STUDENT";
export const SET_ADVISOR_STUDENT_FILTERS = "SET_ADVISOR_STUDENT_FILTERS";
export const RESET_ADVISOR_STUDENT_FILTERS="RESET_ADVISOR_STUDENT_FILTERS";
export const FETCH_ADVISOR_MAIN_DATA = 'FETCH_ADVISOR_MAIN_DATA';
export const ADVISOR_MAIN_DATA_LOADING = 'ADVISOR_MAIN_DATA_LOADING';
export const RESET_ADVISOR_MAIN_DATA = 'RESET_ADVISOR_MAIN_DATA';
export const FETCH_ADVISOR_VISIBILITY = 'FETCH_ADVISOR_VISIBILITY';
export const ADVISOR_TERMS_LOADING = 'ADVISOR_TERMS_LOADING';
export const ADVISOR_TERMS = 'ADVISOR_TERMS';
export const SET_STUDENT_PAGE_NUM = 'SET_STUDENT_PAGE_NUM';
export const SET_ROLE_UUID = 'SET_ROLE_UUID';
export const SET_STUDENT_SEARCH_VALUE = "SET_STUDENT_SEARCH_VALUE";
export const SET_All_COURES_LOADING_VALUE = "SET_All_COURES_LOADING_VALUE";

/** INNOVATIVE STUDENT AND PARENT **/
export const STUDENT_FETCH_STUDENT_LIST_LOADING = "STUDENT_FETCH_STUDENT_LIST_LOADING";
export const STUDENT_FETCH_STUDENT_LIST = "STUDENT_FETCH_STUDENT_LIST";
export const STUDENT_FETCH_ACTING_ROLE_LOADING = "STUDENT_FETCH_ACTING_ROLE_LOADING";
export const STUDENT_FETCH_ACTING_ROLE = "STUDENT_FETCH_ACTING_ROLE";
export const STUDENT_CLEAR_STUDENT_PROFILE_CHART_DATA = "STUDENT_CLEAR_STUDENT_PROFILE_CHART_DATA";
export const STUDENT_FETCH_STUDENT_PROFILE_CHART_DATA = "STUDENT_FETCH_STUDENT_PROFILE_CHART_DATA";
export const STUDENT_FETCH_COURSE = "STUDENT_FETCH_COURSE";
export const STUDENT_FETCH_COURSE_LOADING = "STUDENT_FETCH_COURSE_LOADING";
export const STUDENT_FETCH_COURSE_SETTINGS = "STUDENT_FETCH_COURSE_SETTINGS";
export const STUDENT_FETCH_COURSE_SETTINGS_LOADING = "STUDENT_FETCH_COURSE_SETTINGS_LOADING";
export const STUDENT_FETCH_MASTER_SETTINGS = "STUDENT_FETCH_MASTER_SETTINGS";
export const STUDENT_FETCH_MASTER_SETTINGS_LOADING = "STUDENT_FETCH_MASTER_SETTINGS_LOADING";
export const STUDENT_FETCH_ASSIGNMENTS = "STUDENT_FETCH_ASSIGNMENTS";
export const STUDENT_FETCH_ASSIGNMENTS_LOADING = "STUDENT_FETCH_ASSIGNMENTS_LOADING";
export const STUDENT_FETCH_ASSIGNMENTS_INFORMATION = "STUDENT_FETCH_ASSIGNMENTS_INFORMATION";
export const STUDENT_FETCH_ASSIGNMENTS_INFORMATION_LOADING = "STUDENT_FETCH_ASSIGNMENTS_INFORMATION_LOADING";
export const STUDENT_FETCH_STUDENT_REPORT = "STUDENT_FETCH_STUDENT_REPORT";
export const STUDENT_FETCH_STUDENT_REPORT_LOADING = "STUDENT_FETCH_STUDENT_REPORT_LOADING";
export const STUDENT_FETCH_STUDENT = "STUDENT_FETCH_STUDENT";
export const STUDENT_FETCH_STUDENT_LOADING = "STUDENT_FETCH_STUDENT_LOADING";
export const STUDENT_CLEAR_STUDENT_DATA = "STUDENT_CLEAR_STUDENT_DATA";
export const STUDENT_CLEAR_STUDENT_DETAILS = "STUDENT_CLEAR_STUDENT_DETAILS";
export const STUDENT_FETCH_DUE_ASSIGNMENTS = "STUDENT_FETCH_DUE_ASSIGNMENTS";
export const STUDENT_FETCH_DUE_ASSIGNMENTS_LOADING = "STUDENT_FETCH_DUE_ASSIGNMENTS_LOADING";
export const STUDENT_FETCH_STUDENT_TERMS_LOADING = "STUDENT_FETCH_STUDENT_TERMS_LOADING";
export const STUDENT_FETCH_STUDENT_TERMS = "STUDENT_FETCH_STUDENT_TERMS";
export const STUDENT_FETCH_COURSES = "STUDENT_FETCH_COURSES";
export const STUDENT_FETCH_COURSES_LOADING = "STUDENT_FETCH_COURSES_LOADING";
export const STUDENT_CLEAR_STUDENT_TERMS_DATA = "STUDENT_CLEAR_STUDENT_TERMS_DATA";

/** DASHBOARD MANAGEMENT **/
export const SET_LAYOUT_MENU_SELECTED = "SET_LAYOUT_MENU_SELECTED";
export const SET_DM_LAYOUT_BREADCRUMB = "SET_DM_LAYOUT_BREADCRUMB";
export const SET_SELECTED_DM_LAYOUT_BREADCRUMB = "SET_SELECTED_DM_LAYOUT_BREADCRUMB";
export const FETCH_DASHBOARD_LOADING = "FETCH_DASHBOARD_LOADING";
export const FETCH_DASHBOARD = "FETCH_DASHBOARD";
export const SELECT_DASHBOARD = "SELECT_DASHBOARD";
export const FETCH_USER_ROLES_LOADING = "FETCH_USER_ROLES_LOADING";
export const FETCH_USER_ROLES = "FETCH_USER_ROLES";
export const FETCH_STATS_REPORT_DATA_LOADING = "FETCH_STATS_REPORT_DATA_LOADING";
export const FETCH_STATS_REPORT_DATA = "FETCH_STATS_REPORT_DATA";
export const SAVE_SHARE_ROLES_LOADING = "SAVE_SHARE_ROLES_LOADING";
export const SAVE_SHARE_ROLES = "SAVE_SHARE_ROLES";
export const FETCH_TENANT_ADMIN_LOADING = "FETCH_TENANT_ADMIN_LOADING";
export const FETCH_TENANT_ADMIN = "FETCH_TENANT_ADMIN";
export const SAVE_DASHBOARD_LOADING = "SAVE_DASHBOARD_LOADING";
export const SAVE_DASHBOARD = "SAVE_DASHBOARD";
export const FETCH_DASHBOARD_2FA_CHECK_LOADING = "FETCH_DASHBOARD_2FA_CHECK_LOADING";
export const FETCH_DASHBOARD_2FA_CHECK = "FETCH_DASHBOARD_2FA_CHECK";
export const FETCH_DASHBOARD_OTP_VALIDATE_LOADING = "FETCH_DASHBOARD_OTP_VALIDATE_LOADING";
export const FETCH_DASHBOARD_OTP_VALIDATE = "FETCH_DASHBOARD_OTP_VALIDATE";

/** USER MANAGEMENT **/
export const FETCHING_ROLES_TABLE = 'FETCHING_ROLES_TABLE';
export const ROLES_TABLE_DATA = 'ROLES_TABLE_DATA';
export const FETCHING_USER_TABLE = "FETCHING_USER_TABLE";
export const USER_TABLE_DATA = "USER_TABLE_DATA";
export const FETCHING_ROLES = "FETCHING_ROLES";
export const ROLES_DATA = "ROLES_DATA";
export const ROLE_USERS_DATA = "ROLE_USERS_DATA";
export const FETCHING_ROLE_USERS = "FETCHING_ROLE_USERS";
export const SAVE_ROLES_LOADING = "SAVE_ROLES_LOADING";
export const SAVE_ROLES = "SAVE_ROLES";
export const FETCHING_ACCOUNT_HIERARCHY = "FETCHING_ACCOUNT_HIERARCHY";
export const ACCOUNT_HIERARCHY_DATA = "ACCOUNT_HIERARCHY_DATA";
export const USER_SEARCH_LOADING = "USER_SEARCH_LOADING";
export const USER_SEARCH = "USER_SEARCH";
export const CUSTOM_USER_SEARCH_LOADING = "CUSTOM_USER_SEARCH_LOADING";
export const CUSTOM_USER_SEARCH = "CUSTOM_USER_SEARCH";
export const ROLE_DATA_SYNCING = "ROLE_DATA_SYNCING";
export const SYNC_ROLE_DATA = "SYNC_ROLE_DATA";
export const ROLE_DATA_DELETING = "ROLE_DATA_DELETING";
export const DELETE_ROLE_DATA = "DELETE_ROLE_DATA";
export const SET_SAVE_PAYLOAD = "SET_SAVE_PAYLOAD";
export const INDUVIDUAL_ROLE_FETCHING = "INDUVIDUAL_ROLE_FETCHING";
export const INDUVIDUAL_ROLE_DATA = "INDUVIDUAL_ROLE_DATA";
export const EDIT_ROLE = "EDIT_ROLE";
export const EDIT_ROLE_DATA_LOADING = 'EDIT_ROLE_DATA_LOADING';
export const EDIT_ROLE_DATA = 'EDIT_ROLE_DATA';
export const RESET_SAVE_DATA = 'RESET_SAVE_PAYLOAD_DATA';
export const RESET_INDIVIDUAL_ROLE_DATA = 'RESET_INDIVIDUAL_ROLE_DATA';
export const RESET_PAYLOAD_DATA = 'RESET_PAYLOAD_DATA';
export const FETCHING_COURSE_LIST = 'FETCHING_COURSE_LIST';
export const COURSE_LIST_DATA = 'COURSE_LIST_DATA';
export const SAVE_COURSE_PERMISSION_LOADING = 'SAVE_COURSE_PERMISSION_LOADING';
export const SAVE_COURSE_PERMISSION = 'SAVE_COURSE_PERMISSION';
export const SET_COURSE_PAYLOAD = 'SET_COURSE_PAYLOAD';
export const RESET_COURSE_PAYlOD_DATA = 'RESET_COURSE_PAYlOD_DATA';
export const SET_PERMISSION_PAYLOAD = 'SET_PERMISSION_PAYLOAD';
export const FETCHING_COURSE_PERMISSION = "FETCHING_COURSE_PERMISSION";
export const COURSE_PERMISSION_DATA = "COURSE_PERMISSION_DATA";
export const DELETING_COURSE_PERMISSION = "DELETING_COURSE_PERMISSION";
export const DELETE_COURSE_PERMISSION = "DELETE_COURSE_PERMISSION";
export const FETCHING_SPECIFIC_COURSES = "FETCHING_SPECIFIC_COURSES";
export const SPECIFIC_COURSES_DATA = "SPECIFIC_COURSES_DATA";
export const RESET_PERMISSION_PAYLOAD = "RESET_PERMISSION_PAYLOAD";
export const EDIT_COURSE_PERMISSION_LOADING = "EDIT_COURSE_PERMISSION_LOADING";
export const EDIT_COURSE_PERMISSION = "EDIT_COURSE_PERMISSION";
export const USER_MANAGE_STATUS = "USER_MANAGE_STATUS";
export const USER_MANAGE_STATUS_LOADING = "USER_MANAGE_STATUS_LOADING";
export const FETCHING_STUDENT_USERS = "FETCHING_STUDENT_USERS";
export const ROLE_STUDENT_DATA = "ROLE_STUDENT_DATA";
export const RESET_COURSE_PERMISSION_DATA ="RESET_COURSE_PERMISSION_DATA";
export const RESET_COURSE_PERMISSION_PAYLOAD="RESET_COURSE_PERMISSION_PAYLOAD";
export const CLEAR_SAVE_RESPONCE="CLEAR_SAVE_RESPONCE";
export const CLEAR_EDIT_RESPONCE= "CLEAR_EDIT_RESPONCE";
export const REMOVING_ITEMS_PAYLOAD= "REMOVING_ITEMS_PAYLOAD";
export const CLEAR_ROLE_DATA = "CLEAR_ROLE_DATA";
export const RESET_ROLE_USERS = "RESET_ROLE_USERS";
export const RESET_CUSTOM_SEARCH = "RESET_CUSTOM_SEARCH";
export const FETCHING_STUDENT_ROLES = "FETCHING_STUDENT_ROLES";
export const ROLE_STUDENT_ROLES_DATA = "ROLE_STUDENT_ROLES_DATA";
export const SET_USER_AVAILABILITY = "SET_USER_AVAILABILITY";
export const FETCHING_PERMISSION_INFO = "FETCHING_PERMISSION_INFO";
export const PERMISSION_INFO_DATA = "PERMISSION_INFO_DATA";
export const CLEAR_PERMISSON_INFO = "CLEAR_PERMISSON_INFO";
export const EDITING_CANVAS_STUDENT = "EDITING_CANVAS_STUDENT";
export const EDIT_CANVAS_STUDENT_DATA = "EDIT_CANVAS_STUDENT_DATA";
export const CLEAR_EDIT_CANVAS_STUDENTS = "CLEAR_EDIT_CANVAS_STUDENTS";
export const SET_ROLE_DEATAILS = "SET_ROLE_DEATAILS";
export const CLEAR_COURSE_PAYLOAD = "CLEAR_COURSE_PAYLOAD";
export const CLEAR_STUDENT_ROLE_LIST = "CLEAR_STUDENT_ROLE_LIST";

/** Job Ready common **/
export const SET_JR_LAYOUT_MENU_SELECTED = "SET_JR_LAYOUT_MENU_SELECTED";
export const SET_JR_LAYOUT_BREADCRUMB = "SET_JR_LAYOUT_BREADCRUMB";
export const FETCH_VW_DEALERSHIP_USERS = "FETCH_VW_DEALERSHIP_USERS";
export const FETCH_VW_DEALERSHIP_USERS_LOADING = "FETCH_VW_DEALERSHIP_USERS_LOADING";
export const FETCH_VW_DEALERSHIP_USER = "FETCH_VW_DEALERSHIP_USER";
export const FETCH_VW_DEALERSHIP_USER_LOADING = "FETCH_VW_DEALERSHIP_USER_LOADING";
export const SAVE_VW_DEALERSHIP_USER_LOADING = "SAVE_VW_DEALERSHIP_USER_LOADING";
export const DELETE_VW_DEALERSHIP_USER_LOADING = "DELETE_VW_DEALERSHIP_USER_LOADING";
export const UPDATE_VW_DEALERSHIP_USER_LOADING = "UPDATE_VW_DEALERSHIP_USER_LOADING";
export const FETCH_JR_MY_DASHBOARDS = "FETCH_JR_MY_DASHBOARDS";
export const FETCH_JR_MY_DASHBOARDS_LOADING = "FETCH_JR_MY_DASHBOARDS_LOADING";
export const SAVE_JR_DASHBOARD = "SAVE_JR_DASHBOARD";
export const SAVE_JR_DASHBOARD_LOADING = "SAVE_JR_DASHBOARD_LOADING";
export const FETCH_DASHBOARD_VALIDITY = "FETCH_DASHBOARD_VALIDITY";
export const FETCH_DASHBOARD_VALIDITY_LOADING = "FETCH_DASHBOARD_VALIDITY_LOADING";

/** Credential Management **/
export const FETCH_CREDENTIAL_MANAGEMENT_DATA_LOADING = "FETCH_CREDENTIAL_MANAGEMENT_DATA_LOADING";
export const FETCH_CREDENTIAL_MANAGEMENT_DATA = "FETCH_CREDENTIAL_MANAGEMENT_DATA";
export const UPDATE_DEVELOPER_KEY_DATA = "UPDATE_DEVELOPER_KEY_DATA";
export const UPDATE_DEVELOPER_KEY_DATA_LOADING = "UPDATE_DEVELOPER_KEY_DATA_LOADING";
export const ADD_ADMIN_TOKEN_DATA = "ADD_ADMIN_TOKEN";
export const ADD_ADMIN_TOKEN_LOADING = "ADD_ADMIN_TOKEN_LOADING";
export const DELETE_ADMIN_TOKEN_DATA = "DELETE_ADMIN_TOKEN_DATA";
export const DELETE_ADMIN_TOKEN_LOADING = "DELETE_ADMIN_TOKEN_LOADING";
export const DELETE_DEVELOPER_KEY_DATA = "DELETE_DEVELOPER_KEY_DATA";



/** Volkswagen Dealership **/
export const FETCH_SETTING_SIDEBAR_DATA = "FETCH_SETTING_SIDEBAR_DATA";
export const FETCH_SETTING_SIDEBAR_DATA_LOADING = "FETCH_SETTING_SIDEBAR_DATA_LOADING";
export const FETCH_SETTING_SIDEBAR_UNITS_LOADING = "FETCH_SETTING_SIDEBAR_UNITS_LOADING";
export const FETCH_SETTING_SIDEBAR_UNITS = "FETCH_SETTING_SIDEBAR_UNITS";
export const SET_OPEN_PANELS = "SET_OPEN_PANELS";
export const SET_SELECTED_PROGRAM = "SET_SELECTED_PROGRAM";
export const SET_EDIT_MODE = "SET_EDIT_MODE";
export const CLEAR_SETTING_UNITS_DATA = "CLEAR_SETTING_UNITS_DATA";
export const SAVE_SETTING_UNITS_ORDER_DATA = "SAVE_SETTING_UNITS_ORDER_DATA";
export const SAVE_SETTING_UNITS_ORDER_DATA_LOADING = "SAVE_SETTING_UNITS_ORDER_DATA_LOADING";
export const SAVE_SETTING_UNITS_DATA = "SAVE_SETTING_UNITS_DATA";
export const SAVE_SETTING_UNITS_DATA_LOADING = "SAVE_SETTING_UNITS_DATA_LOADING";
export const FETCH_DEALERSHIP_SIDEBAR_DATA = "FETCH_DEALERSHIP_SIDEBAR_DATA";
export const FETCH_DEALERSHIP_SIDEBAR_DATA_LOADING = "FETCH_DEALERSHIP_SIDEBAR_DATA_LOADING";
export const CLEAR_DEALERSHIP_SIDEBAR_DATA = "CLEAR_DEALERSHIP_SIDEBAR_DATA";
export const SET_DEALERSHIP_FILTERED_SIDEBAR_DATA = "SET_DEALERSHIP_FILTERED_SIDEBAR_DATA";
export const FETCH_DEALERSHIP_MAIN_REPORT_DATA = "FETCH_DEALERSHIP_MAIN_REPORT_DATA";
export const FETCH_DEALERSHIP_MAIN_REPORT_DATA_LOADING = "FETCH_DEALERSHIP_MAIN_REPORT_DATA_LOADING";
export const FETCH_ONLINE_MODULES_DATA = "FETCH_ONLINE_MODULES_DATA";
export const FETCH_ONLINE_MODULES_DATA_LOADING = "FETCH_ONLINE_MODULES_DATA_LOADING";
export const FETCH_ONLINE_STUDENTS_DATA = "FETCH_ONLINE_STUDENTS_DATA";
export const FETCH_ONLINE_STUDENTS_DATA_LOADING = "FETCH_ONLINE_STUDENTS_DATA_LOADING";
export const CLEAR_PATHWAY_TIMELINE_DATA = "CLEAR_PATHWAY_TIMELINE_DATA";
export const FETCH_PATHWAY_TIMELINE_DATA = "FETCH_PATHWAY_TIMELINE_DATA";
export const FETCH_PATHWAY_TIMELINE_DATA_LOADING = "FETCH_PATHWAY_TIMELINE_DATA_LOADING";
export const FETCH_PATHWAY_STUDENTS_DATA = "FETCH_PATHWAY_STUDENTS_DATA";
export const FETCH_PATHWAY_STUDENTS_DATA_LOADING = "FETCH_PATHWAY_STUDENTS_DATA_LOADING";

/** External Auth **/
export const USER_SIGN_IN = "USER_SIGN_IN";
export const USER_SIGN_IN_LOADING = "USER_SIGN_IN_LOADING";
export const USER_SIGN_OUT = "USER_SIGN_OUT";
export const USER_SIGN_OUT_LOADING = "USER_SIGN_OUT_LOADING";
export const USER_RESET_PASSWORD = "USER_RESET_PASSWORD";
export const USER_RESET_PASSWORD_LOADING = "USER_RESET_PASSWORD_LOADING";
export const USER_FETCH_DEALERSHIPS = "USER_FETCH_DEALERSHIPS";
export const USER_FETCH_DEALERSHIPS_LOADING = "USER_FETCH_DEALERSHIPS_LOADING";
export const USER_RESET_PW_LINK_SEND_LOADING = "USER_RESET_PW_LINK_SEND_LOADING";

/** TENTACLE META **/
export const GET_DOMAIN_META_LOADING = "GET_DOMAIN_META_LOADING";
export const GET_DOMAIN_META = "GET_DOMAIN_META";

/** JOB READY GENERIC USER MANAGEMENT **/
export const SET_SELECTED_DASHBOARD = "SET_SELECTED_DASHBOARD";
export const FETCH_DASHBOARD_SELECTIONS = "FETCH_DASHBOARD_SELECTIONS";
export const FETCH_DASHBOARD_SELECTIONS_LOADING = "FETCH_DASHBOARD_SELECTIONS_LOADING";
export const FETCH_DASHBOARD_USERS = "FETCH_DASHBOARD_USERS";
export const FETCH_DASHBOARD_USERS_LOADING = "FETCH_DASHBOARD_USERS_LOADING";
export const ADD_NEW_DASHBOARD_USER = "ADD_NEW_DASHBOARD_USER";
export const ADD_NEW_DASHBOARD_USER_LOADING = "ADD_NEW_DASHBOARD_USER_LOADING";
export const DELETE_DASHBOARD_USER = "DELETE_DASHBOARD_USER";
export const DELETE_DASHBOARD_USER_LOADING = "DELETE_DASHBOARD_USER_LOADING";
export const JR_GEN_USER_RESET_PASSWORD = "JR_GEN_USER_RESET_PASSWORD";
export const JR_GEN_USER_RESET_PASSWORD_LOADING = "JR_GEN_USER_RESET_PASSWORD_LOADING";


/** JOB READY GENERIC **/
export const SET_SIDEBAR_DATA = "SET_SIDEBAR_DATA";

// SETTINGS

// COMMON
export const JR_GEN_FETCH_USER_PERMISSIONS = "JR_GEN_FETCH_USER_PERMISSIONS";
export const JR_GEN_FETCH_USER_PERMISSIONS_LOADING = "JR_GEN_FETCH_USER_PERMISSIONS_LOADING";
export const JR_GEN_SETTINGS_FETCH = "JR_GEN_SETTINGS_FETCH";
export const JR_GEN_SETTINGS_FETCH_LOADING = "JR_GEN_SETTINGS_FETCH_LOADING";
export const JR_GEN_SETTINGS_ALTERNATION = "JR_GEN_SETTINGS_ALTERNATION";
export const JR_GEN_SETTINGS_SAVE = "JR_GEN_SETTINGS_SAVE";
export const JR_GEN_SETTINGS_SAVE_LOADING = "JR_GEN_SETTINGS_SAVE_LOADING";
export const JR_GEN_SETTINGS_RESET_EDIT_MODE = "JR_GEN_SETTINGS_RESET_EDIT_MODE";
export const JR_GEN_SETTINGS_DISCARD_DRAFT = "JR_GEN_SETTINGS_DISCARD_DRAFT";
export const JR_GEN_SETTINGS_DISCARD_DRAFT_LOADING = "JR_GEN_SETTINGS_DISCARD_DRAFT_LOADING";

// Data sources
export const JR_GEN_DATA_SOURCES_OPTIONS_FETCH = "JR_GEN_DATA_SOURCES_OPTIONS_FETCH";
export const JR_GEN_DATA_SOURCES_OPTIONS_FETCH_LOADING = "JR_GEN_DATA_SOURCES_OPTIONS_FETCH_LOADING";

// Risk Calculations
export const JR_GEN_CATEGORY_OPTIONS_FETCH = "JR_GEN_CATEGORY_OPTIONS_FETCH";
export const JR_GEN_CATEGORY_OPTIONS_FETCH_LOADING = "JR_GEN_CATEGORY_OPTIONS_FETCH_LOADING";


// RISK ANALYSIS

// COMMON
export const JR_GEN_SIDEBAR_FILTERS_FETCH = "JR_GEN_SIDEBAR_FILTERS_FETCH";
export const JR_GEN_SIDEBAR_FILTERS_FETCH_LOADING = "JR_GEN_SIDEBAR_FILTERS_FETCH_LOADING";
export const JR_GEN_SIDEBAR_FILTERS_SELECT = "JR_GEN_SIDEBAR_FILTERS_SELECT";
export const JR_GEN_SET_SELECTED_CARDS = "JR_GEN_SET_SELECTED_CARDS";

// Overview
export const JR_GEN_OVERVIEW_CHARTS_FETCH = "JR_GEN_OVERVIEW_CHARTS_FETCH";
export const JR_GEN_OVERVIEW_CHARTS_FETCH_LOADING = "JR_GEN_OVERVIEW_CHARTS_FETCH_LOADING";
export const JR_GEN_OVERVIEW_STUDENTS_FETCH = "JR_GEN_OVERVIEW_STUDENTS_FETCH";
export const JR_GEN_OVERVIEW_STUDENTS_FETCH_LOADING = "JR_GEN_OVERVIEW_STUDENTS_FETCH_LOADING";

// Academic
export const JR_GEN_ACADEMIC_CARDS_DATA_FETCH = "JR_GEN_ACADEMIC_CARDS_DATA_FETCH";
export const JR_GEN_ACADEMIC_CARDS_DATA_FETCH_LOADING = "JR_GEN_ACADEMIC_CARDS_DATA_FETCH_LOADING";
export const JR_GEN_ACADEMIC_STUDENTS_DATA_FETCH = "JR_GEN_ACADEMIC_STUDENTS_DATA_FETCH";
export const JR_GEN_ACADEMIC_STUDENTS_DATA_FETCH_LOADING = "JR_GEN_ACADEMIC_STUDENTS_DATA_FETCH_LOADING";
export const JR_GEN_ACADEMIC_OVERVIEW_DATA_FETCH = "JR_GEN_ACADEMIC_OVERVIEW_DATA_FETCH";
export const JR_GEN_ACADEMIC_OVERVIEW_DATA_FETCH_LOADING = "JR_GEN_ACADEMIC_OVERVIEW_DATA_FETCH_LOADING";

export const JR_GEN_ACADEMIC_DISABILITIES_DATA_FETCH = "JR_GEN_ACADEMIC_DISABILITIES_DATA_FETCH";
export const JR_GEN_ACADEMIC_DISABILITIES_DATA_FETCH_LOADING = "JR_GEN_ACADEMIC_DISABILITIES_DATA_FETCH_LOADING";
export const JR_GEN_ACADEMIC_REQUIREMENT_MET_DATA_FETCH = "JR_GEN_ACADEMIC_REQUIREMENT_MET_DATA_FETCH";
export const JR_GEN_ACADEMIC_REQUIREMENT_MET_DATA_FETCH_LOADING = "JR_GEN_ACADEMIC_REQUIREMENT_MET_DATA_FETCH_LOADING";
export const JR_GEN_ACADEMIC_TARGET_NOT_COMPLETED_DATA_FETCH = "JR_GEN_ACADEMIC_TARGET_NOT_COMPLETED_DATA_FETCH";
export const JR_GEN_ACADEMIC_TARGET_NOT_COMPLETED_DATA_FETCH_LOADING = "JR_GEN_ACADEMIC_TARGET_NOT_COMPLETED_DATA_FETCH_LOADING";
export const JR_GEN_ACADEMIC_UNIT_PROGRESS_DATA_FETCH = "JR_GEN_ACADEMIC_UNIT_PROGRESS_DATA_FETCH";
export const JR_GEN_ACADEMIC_UNIT_PROGRESS_DATA_FETCH_LOADING = "JR_GEN_ACADEMIC_UNIT_PROGRESS_DATA_FETCH_LOADING";
export const JR_GEN_ACADEMIC_ATTENDANCE_DATA_FETCH = "JR_GEN_ACADEMIC_ATTENDANCE_DATA_FETCH";
export const JR_GEN_ACADEMIC_ATTENDANCE_DATA_FETCH_LOADING = "JR_GEN_ACADEMIC_ATTENDANCE_DATA_FETCH_LOADING";

// Financial
export const JR_GEN_FINANCIAL_CARDS_DATA_FETCH = "JR_GEN_FINANCIAL_CARDS_DATA_FETCH";
export const JR_GEN_FINANCIAL_CARDS_DATA_FETCH_LOADING = "JR_GEN_FINANCIAL_CARDS_DATA_FETCH_LOADING";
export const JR_GEN_FINANCIAL_STUDENTS_DATA_FETCH = "JR_GEN_FINANCIAL_STUDENTS_DATA_FETCH";
export const JR_GEN_FINANCIAL_STUDENTS_DATA_FETCH_LOADING = "JR_GEN_FINANCIAL_STUDENTS_DATA_FETCH_LOADING";
export const JR_GEN_FINANCIAL_OVERVIEW_DATA_FETCH = "JR_GEN_FINANCIAL_OVERVIEW_DATA_FETCH";
export const JR_GEN_FINANCIAL_OVERVIEW_DATA_FETCH_LOADING = "JR_GEN_FINANCIAL_OVERVIEW_DATA_FETCH_LOADING";

// Compliance
export const JR_GEN_COMPLIANCE_CARDS_DATA_FETCH = "JR_GEN_COMPLIANCE_CARDS_DATA_FETCH";
export const JR_GEN_COMPLIANCE_CARDS_DATA_FETCH_LOADING = "JR_GEN_COMPLIANCE_CARDS_DATA_FETCH_LOADING";
export const JR_GEN_COMPLIANCE_STUDENTS_DATA_FETCH = "JR_GEN_COMPLIANCE_STUDENTS_DATA_FETCH";
export const JR_GEN_COMPLIANCE_STUDENTS_DATA_FETCH_LOADING = "JR_GEN_COMPLIANCE_STUDENTS_DATA_FETCH_LOADING";
export const JR_GEN_COMPLIANCE_OVERVIEW_DATA_FETCH = "JR_GEN_COMPLIANCE_OVERVIEW_DATA_FETCH";
export const JR_GEN_COMPLIANCE_OVERVIEW_DATA_FETCH_LOADING = "JR_GEN_COMPLIANCE_OVERVIEW_DATA_FETCH_LOADING";

export const JR_GEN_COMPLIANCE_DURATION_TO_EXPIRE_DATA_FETCH = "JR_GEN_COMPLIANCE_DURATION_TO_EXPIRE_DATA_FETCH";
export const JR_GEN_COMPLIANCE_DURATION_TO_EXPIRE_DATA_FETCH_LOADING = "JR_GEN_COMPLIANCE_DURATION_TO_EXPIRE_DATA_FETCH_LOADING";
export const JR_GEN_COMPLIANCE_FILE_NOTES_DATA_FETCH = "JR_GEN_COMPLIANCE_FILE_NOTES_DATA_FETCH";
export const JR_GEN_COMPLIANCE_FILE_NOTES_DATA_FETCH_LOADING = "JR_GEN_COMPLIANCE_FILE_NOTES_DATA_FETCH_LOADING";

// ACC Parent Dashboard
export const ACC_SET_PARENT_DASHBOARD_LOADING = "ACC_SET_PARENT_DASHBOARD_LOADING";
export const ACC_SET_STUDENT_PROFILE = "ACC_SET_STUDENT_PROFILE";
export const ACC_SET_PARENT_STUDENTS = "ACC_SET_PARENT_STUDENTS";
export const ACC_SET_SUBJECT_ANALYSIS = "ACC_SET_SUBJECT_ANALYSIS";
export const ACC_SET_CURRENT_STUDENT_LOADING = "ACC_SET_CURRENT_STUDENT_LOADING";
export const ACC_SET_SUBJECT_ANALYSIS_LOADING = "ACC_SET_SUBJECT_ANALYSIS_LOADING";
export const ACC_OVERRIDE_SUBJECT_ANALYSIS = 'ACC_OVERRIDE_SUBJECT_ANALYSIS'
export const ACC_SET_SELECTED_ITEMS_LOADING = 'ACC_SET_SELECTED_ITEMS_LOADING'

// HELP AND SUPPORT
export const HELP_SUBMITTING_FEEDBACK = "HELP_SUBMITTING_FEEDBACK";
export const HELP_SUBMITTED_FEEDBACK = "HELP_SUBMITTED_FEEDBACK";

// App Testing
export const SEARCH_USER_BY_USERNAME_LOADING = "SEARCH_USER_BY_USERNAME_LOADING";
export const SEARCH_USER_BY_USERNAME = "SEARCH_USER_BY_USERNAME";

// VETTRACK
export const VTT_SET_SIDEBAR_DATA = 'VTT_SET_SIDEBAR_DATA';

// VETTRACK_ANALYSIS_RISK_ANALYSIS
export const VTT_SIDEBAR_FILTERS_FETCH = "VTT_SIDEBAR_FILTERS_FETCH";
export const VTT_SIDEBAR_FILTERS_FETCH_LOADING = "VTT_SIDEBAR_FILTERS_FETCH_LOADING";
export const VTT_SIDEBAR_FILTERS_SELECT = "VTT_SIDEBAR_FILTERS_SELECT";
export const VTT_SET_SELECTED_CARDS = "VTT_SET_SELECTED_CARDS";

export const VTT_ACADEMIC_CARDS_DATA_FETCH =
  'VTT_ACADEMIC_CARDS_DATA_FETCH';
export const VTT_ACADEMIC_CARDS_DATA_FETCH_LOADING =
  'VTT_ACADEMIC_CARDS_DATA_FETCH_LOADING';
export const VTT_ACADEMIC_STUDENTS_DATA_FETCH =
  'VTT_ACADEMIC_STUDENTS_DATA_FETCH';
export const VTT_ACADEMIC_STUDENTS_DATA_FETCH_LOADING =
  'VTT_ACADEMIC_STUDENTS_DATA_FETCH_LOADING';
export const VTT_ACADEMIC_OVERVIEW_DATA_FETCH =
  'VTT_ACADEMIC_OVERVIEW_DATA_FETCH';
export const VTT_ACADEMIC_OVERVIEW_DATA_FETCH_LOADING =
  'VTT_ACADEMIC_OVERVIEW_DATA_FETCH_LOADING';
export const VTT_ACADEMIC_DISABILITIES_DATA_FETCH =
  'VTT_ACADEMIC_DISABILITIES_DATA_FETCH';
export const VTT_ACADEMIC_DISABILITIES_DATA_FETCH_LOADING =
  'VTT_ACADEMIC_DISABILITIES_DATA_FETCH_LOADING';
export const VTT_ACADEMIC_UNIT_PROGRESS_DATA_FETCH =
  'VTT_ACADEMIC_UNIT_PROGRESS_DATA_FETCH';
export const VTT_ACADEMIC_UNIT_PROGRESS_DATA_FETCH_LOADING =
  'VTT_ACADEMIC_UNIT_PROGRESS_DATA_FETCH_LOADING';
export const VTT_ACADEMIC_ATTENDANCE_DATA_FETCH =
  'VTT_ACADEMIC_ATTENDANCE_DATA_FETCH';
export const VTT_ACADEMIC_ATTENDANCE_DATA_FETCH_LOADING =
  'VTT_ACADEMIC_ATTENDANCE_DATA_FETCH_LOADING';

export const VTT_FINANCIAL_CARDS_DATA_FETCH = "VTT_FINANCIAL_CARDS_DATA_FETCH";
export const VTT_FINANCIAL_CARDS_DATA_FETCH_LOADING = "VTT_FINANCIAL_CARDS_DATA_FETCH_LOADING";
export const VTT_FINANCIAL_STUDENTS_DATA_FETCH = "VTT_FINANCIAL_STUDENTS_DATA_FETCH";
export const VTT_FINANCIAL_STUDENTS_DATA_FETCH_LOADING = "VTT_FINANCIAL_STUDENTS_DATA_FETCH_LOADING";
export const VTT_FINANCIAL_OVERVIEW_DATA_FETCH = "VTT_FINANCIAL_OVERVIEW_DATA_FETCH";
export const VTT_FINANCIAL_OVERVIEW_DATA_FETCH_LOADING = "VTT_FINANCIAL_OVERVIEW_DATA_FETCH_LOADING";

export const VTT_COMPLIANCE_CARDS_DATA_FETCH = "VTT_COMPLIANCE_CARDS_DATA_FETCH";
export const VTT_COMPLIANCE_CARDS_DATA_FETCH_LOADING = "VTT_COMPLIANCE_CARDS_DATA_FETCH_LOADING";
export const VTT_COMPLIANCE_STUDENTS_DATA_FETCH = "VTT_COMPLIANCE_STUDENTS_DATA_FETCH_LOADING";
export const VTT_COMPLIANCE_STUDENTS_DATA_FETCH_LOADING = "VTT_COMPLIANCE_STUDENTS_DATA_FETCH_LOADING";
export const VTT_COMPLIANCE_OVERVIEW_DATA_FETCH = "VTT_COMPLIANCE_OVERVIEW_DATA_FETCH";
export const VTT_COMPLIANCE_OVERVIEW_DATA_FETCH_LOADING = "VTT_COMPLIANCE_OVERVIEW_DATA_FETCH_LOADING";
export const VTT_COMPLIANCE_DURATION_TO_EXPIRE_DATA_FETCH = "VTT_COMPLIANCE_DURATION_TO_EXPIRE_DATA_FETCH";
export const VTT_COMPLIANCE_DURATION_TO_EXPIRE_DATA_FETCH_LOADING = "VTT_COMPLIANCE_DURATION_TO_EXPIRE_DATA_FETCH_LOADING";
export const VTT_COMPLIANCE_RISK_COMPLIANCE_POPUP_DATA_FETCH = "VTT_COMPLIANCE_RISK_COMPLIANCE_POPUP_DATA_FETCH";
export const VTT_COMPLIANCE_RISK_COMPLIANCE_POPUP_FETCH_LOADING = "VTT_COMPLIANCE_RISK_COMPLIANCE_POPUP_FETCH_LOADING";

export const VTT_OVERVIEW_CHARTS_FETCH = "VTT_OVERVIEW_CHARTS_FETCH";
export const VTT_OVERVIEW_CHARTS_FETCH_LOADING = "VTT_OVERVIEW_CHARTS_FETCH_LOADING";
export const VTT_OVERVIEW_STUDENTS_FETCH = "VTT_OVERVIEW_STUDENTS_FETCH";
export const VTT_OVERVIEW_STUDENTS_FETCH_LOADING = "VTT_OVERVIEW_STUDENTS_FETCH_LOADING";

// VETTRACK_ANALYSIS_SETTINGS
export const VTT_FETCH_USER_PERMISSIONS = 'VTT_FETCH_USER_PERMISSIONS';
export const VTT_FETCH_USER_PERMISSIONS_LOADING = "VTT_FETCH_USER_PERMISSIONS_LOADING";
export const VTT_SETTINGS_ALTERNATION = "VTT_SETTINGS_ALTERNATION";
export const VTT_SETTINGS_RESET_EDIT_MODE = "VTT_SETTINGS_RESET_EDIT_MODE";
export const VTT_SETTINGS_DISCARD_DRAFT = "VTT_SETTINGS_DISCARD_DRAFT";
export const VTT_SETTINGS_DISCARD_DRAFT_LOADING = "VTT_SETTINGS_DISCARD_DRAFT_LOADING";

export const VTT_SETTINGS_FETCH = "VTT_SETTINGS_FETCH";
export const VTT_SETTINGS_FETCH_LOADING = "VTT_SETTINGS_FETCH_LOADING";
export const VTT_DATA_SOURCES_OPTIONS_FETCH = "VTT_DATA_SOURCES_OPTIONS_FETCH";
export const VTT_DATA_SOURCES_OPTIONS_FETCH_LOADING = "VTT_DATA_SOURCES_OPTIONS_FETCH_LOADING";
export const VTT_SETTINGS_SAVE = "VTT_SETTINGS_SAVE";
export const VTT_SETTINGS_SAVE_LOADING = "VTT_SETTINGS_SAVE_LOADING";
export const VTT_SET_SETTINGS_ERRORS = "VTT_SET_SETTINGS_ERRORS";

export const VTT_CATEGORY_OPTIONS_FETCH = "VTT_CATEGORY_OPTIONS_FETCH";
export const VTT_CATEGORY_OPTIONS_FETCH_LOADING = "VTT_CATEGORY_OPTIONS_FETCH_LOADING";

export const VTT_SET_DATA_SOURCES_SYNC_STATUS = "VTT_SET_DATA_SOURCES_SYNC_STATUS";
export const VTT_RC_SET_SYNC_STATUS = "VTT_RC_SET_SYNC_STATUS";
export const VTT_RI_SET_SYNC_STATUS = "VTT_RI_SET_SYNC_STATUS";

// VETTRACK_DASHBOARD

export const SET_VTT_LAYOUT_BREADCRUMB = "SET_VTT_LAYOUT_BREADCRUMB";
export const SET_VTT_LAYOUT_MENU_SELECTED = "SET_VTT_LAYOUT_MENU_SELECTED";

export const VTT_FETCH_MY_DASHBOARDS_LOADING = 'VTT_FETCH_MY_DASHBOARDS_LOADING';
export const VTT_FETCH_MY_DASHBOARDS = 'VTT_FETCH_MY_DASHBOARDS';
export const VTT_SAVE_DASHBOARD = 'VTT_SAVE_DASHBOARD';
export const VTT_SAVE_DASHBOARD_LOADING = 'VTT_SAVE_DASHBOARD_LOADING';
export const VTT_FETCH_DASHBOARD_VALIDITY = 'VTT_FETCH_DASHBOARD_VALIDITY';
export const VTT_FETCH_DASHBOARD_VALIDITY_LOADING = 'VTT_FETCH_DASHBOARD_VALIDITY_LOADING';

export const VTT_SET_SELECTED_DASHBOARD = 'VTT_SET_SELECTED_DASHBOARD';
export const VTT_FETCH_DASHBOARD_SELECTIONS = 'VTT_FETCH_DASHBOARD_SELECTIONS';
export const VTT_FETCH_DASHBOARD_SELECTIONS_LOADING = 'VTT_FETCH_DASHBOARD_SELECTIONS_LOADING';
export const VTT_FETCH_DASHBOARD_USERS = 'VTT_FETCH_DASHBOARD_USERS';
export const VTT_FETCH_DASHBOARD_USERS_LOADING = 'VTT_FETCH_DASHBOARD_USERS_LOADING'
export const VTT_ADD_NEW_DASHBOARD_USER = 'VTT_ADD_NEW_DASHBOARD_USER';
export const VTT_ADD_NEW_DASHBOARD_USER_LOADING = 'VTT_ADD_NEW_DASHBOARD_USER_LOADING';
export const VTT_DELETE_DASHBOARD_USER = 'VTT_DELETE_DASHBOARD_USER';
export const VTT_DELETE_DASHBOARD_USER_LOADING = 'VTT_DELETE_DASHBOARD_USER_LOADING';
export const VTT_USER_RESET_PASSWORD = 'VTT_USER_RESET_PASSWORD'
export const VTT_USER_RESET_PASSWORD_LOADING = 'VTT_USER_RESET_PASSWORD_LOADING'

// VTTRACK_RTO

export const VTT_RTO_SIGN_UP_DATA_SAVING = 'VTT_RTO_SIGN_UP_DATA_SAVING';
// END VETTRACK

// clustering
export const RESET_ADMIN_LEVEL_DATA = "RESET_ADMIN_LEVEL_DATA";
export const FETCH_STUDENT_CLUSTER_DATA_LOADING = "FETCH_STUDENT_CLUSTER_DATA_LOADING";
export const FETCH_STUDENT_CLUSTER_DATA = "FETCH_STUDENT_CLUSTER_DATA";
export const FETCH_CHAT_HISTORY_DATA_LOADING = "FETCH_CHAT_HISTORY_DATA_LOADING";
export const FETCH_CHAT_HISTORY_DATA = "FETCH_CHAT_HISTORY_DATA";
export const FETCH_CHAT_HISTORY_ERROR_HANDEL = "FETCH_CHAT_HISTORY_ERROR_HANDEL";
export const SEND_CHAT_MESSAGE_LOADING = "SEND_CHAT_MESSAGE_LOADING";
export const SEND_CHAT_MESSAGE_DATA = "SEND_CHAT_MESSAGE_DATA";
export const UPDATE_SEND_CHAT = "UPDATE_SEND_CHAT";
export const SET_STUDENT_CLUSTER_ANALYZER_STATUS = "SET_STUDENT_CLUSTER_ANALYZER_STATUS";
export const SET_STUDENT_CLUSTER_ANALYZER_LOADING = "SET_STUDENT_CLUSTER_ANALYZER_LOADING";
export const FETCHING_CLUSTER_SETTINGS_DATA = "FETCHING_CLUSTER_SETTINGS_DATA";
export const CLUSTER_SETTINGS_DATA = "CLUSTER_SETTINGS_DATA";
export const GET_INFO = "GET_INFO";
export const FETCHING_GET_INFO = "FETCHING_GET_INFO";
export const RESET_STUDENT_CLASSIFICATION_DATA = "RESET_STUDENT_CLASSIFICATION_DATA";
export const RESET_CHAT_COUNT_DOWN_TIMER = "RESET_CHAT_COUNT_DOWN_TIMER";
export const UPDATE_SECTION_FILTER_VALUES = "UPDATE_SECTION_FILTER_VALUES";
export const RESET_SECTION_FILTER_VALUES = "RESET_SECTION_FILTER_VALUES";
export const UPDATE_CLUSTER_MESSAGE_VISIBILITY = "UPDATE_CLUSTER_MESSAGE_VISIBILITY";
export const FETCH_CLUSTER_MASSAGES = "FETCH_CLUSTER_MASSAGES";
export const RESET_CLUSTER_MASSAGES = "RESET_CLUSTER_MASSAGES";

//dataPrivacyManagement
export const DATA_PRIVACY_SETTINGS_DATA="DATA_PRIVACY_SETTINGS_DATA";
export const FETCHING_DATA_PRIVACY_SETTINGS_DATA="FETCHING_DATA_PRIVACY_SETTINGS_DATA";
export const SET_DATA_PRIVACY_MANAGER_LOADING="SET_DATA_PRIVACY_MANAGER_LOADING";
export const SET_DATA_PRIVACY_MANAGER_STATUS="SET_DATA_PRIVACY_MANAGER_STATUS";
export const DATA_PRIVACY_ACCESS_GRANTED_DATA = "DATA_PRIVACY_ACCESS_GRANTED_DATA";
export const DATA_PRIVACY_ACCESS_GRANTED_DATA_LOADING = "DATA_PRIVACY_ACCESS_GRANTED_DATA_LOADING";
export const RESET_DATA_PRIVACY_ACCESS_GRANTED_DATA = "RESET_DATA_PRIVACY_ACCESS_GRANTED_DATA";

/** NOTIFICATION MANAGEMENT **/
export const SENDING_INDIVIDUAL_NOTIFICATION = "SENDING_INDIVIDUAL_NOTIFICATION";
export const SENT_INDIVIDUAL_NOTIFICATION = "SENT_INDIVIDUAL_NOTIFICATION";
export const SAVING_NOTIFICATION_SETTINGS = "SAVING_NOTIFICATION_SETTINGS";
export const SAVED_NOTIFICATION_SETTINGS = "SAVED_NOTIFICATION_SETTINGS";
export const FETCHING_NOTIFICATION_SETTINGS = "FETCHING_NOTIFICATION_SETTINGS";
export const FETCHED_NOTIFICATION_SETTINGS = "FETCHED_NOTIFICATION_SETTINGS";
export const FETCHED_NOTIFIER = "FETCHED_NOTIFIER";
export const FETCHING_NOTIFIER = "FETCHING_NOTIFIER";
