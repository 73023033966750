import _get from 'lodash/get';
import { createSelector } from 'reselect';

const notificationManagementData = (state) => _get(state, "notificationManagementReducer", {});

export const getSendIndividualNotificationData = createSelector(
    notificationManagementData,
    (data) => _get(data, 'sendNotification.data', [])
);

export const getSendIndividualNotificationLoading = createSelector(
    notificationManagementData,
    (data) => _get(data, 'sendNotification.isLoading', false)
);

export const getSaveNotificationSettingsData = createSelector(
    notificationManagementData,
    (data) => _get(data, 'notificationSettings.data', [])
);

export const getSaveNotificationSettingsLoading = createSelector(
    notificationManagementData,
    (data) => _get(data, 'notificationSettings.isLoading', false)
);

export const getFetchedNotificationSettingsData = createSelector(
    notificationManagementData,
    (data) => _get(data, 'fetchNotificationSettings.data.data',{})
);

export const getFetchedNotificationSettingsLoading = createSelector(
    notificationManagementData,
    (data) => _get(data, 'fetchNotificationSettings.isLoading', false)
);

export const getFetchedNotifier = createSelector(
    notificationManagementData,
    (data) => _get(data, 'fetchNotifier.data.data',[])
);

export const getFetchedNotifierLoading = createSelector(
    notificationManagementData,
    (data) => _get(data, 'fetchNotifier.isLoading', false)
);
