import React, { useEffect, useState } from "react";
import { Col, Form, Input, Row } from "antd";
const { TextArea } = Input;

const NotificationMessageComponent = ({ sub, message, setSub, setMessage, errors }) => {
  const [content, setContent] = useState('');
  const [subject, setSubject] = useState('');

  const onSubjectChange = e => {
    const subjectLine = e.target.value;
    setSubject(subjectLine);
  }

  const onBodyChange = e => {
    const textChange = e.target.value;
    setContent(textChange);
  }

  useEffect(() => {
    setContent(message);
  }, [message]);

  useEffect(() => {
    setSubject(sub);
  }, [sub]);

  useEffect(() => {
    setSub(subject);
  }, [subject]);

  useEffect(() => {
    setMessage(content);
  }, [content]);

  return (
    <>
      <Row>
        <Col span={24}>
          <Form.Item colon={false} label="Subject" labelAlign="left">
            <Input
              onChange={onSubjectChange}
              value={subject}
            />
            {errors.subject && <div style={{ color: 'red' }}>{errors.subject}</div>}
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Form.Item colon={false} label="Message" labelAlign="left">
            <TextArea rows={6}
              showCount
              onChange={onBodyChange}
              value={content}
              maxLength={200}
            />
            {errors.message && <div style={{ color: 'red' }}>{errors.message}</div>}
          </Form.Item>
        </Col>
      </Row>
    </>
  )
}

export default NotificationMessageComponent;
