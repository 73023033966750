import React, { useState, useEffect } from "react";
import { Select } from "antd";

const rolesList = [
  { value: "teacher", label: "Teacher" },
  { value: "parent", label: "Parent" },
  { value: "student", label: "Student" },
];

const RoleSelect = ({ rolesChange, savedRoles }) => {
  const [roles, setRoles] = useState([]);

  const onRolesChange = (value, options) => {
    setRoles(value);
    rolesChange(options);
  };

  useEffect(() => {
    if (savedRoles) {
      rolesChange(savedRoles);
      const savedValues = savedRoles.map((item) => item.value);
      setRoles(savedValues);
    }
  }, [savedRoles]);

  return (
    <Select
      mode="multiple"
      value={roles}
      placeholder={"Search student"}
      onChange={onRolesChange}
      options={rolesList}
    />
  );
};

export default RoleSelect;
