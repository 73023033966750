import React, { useState, useEffect } from "react";
import { DatePicker, ConfigProvider } from "antd";
import moment from "moment";

const DateTimePicker = ({ input, date }) => {
  const [selectedDate, setSelectedDate] = useState(null);
  const dateFormat = "DD/MM/YYYY hh:mm A";

  useEffect(() => {
    if (input) {
      setSelectedDate(moment(input));
    }
  }, [input]);

  useEffect(() => {
    const isoString = moment(selectedDate).toISOString();
    date(isoString);
  }, [selectedDate]);

  const disabledDate = (current) => {
    const now = moment().startOf("day");
    return current && current < now;
  };

  const handleDateChange = (value) => {
    setSelectedDate(value);
  };

  return (
    <ConfigProvider>
      <DatePicker
        showTime={{
          format: "hh:mm A",
          showNow: false,
        }}
        format={dateFormat}
        disabledDate={disabledDate}
        onOk={handleDateChange}
        value={selectedDate}
        style={{ width: "100%" }}
        showNow={false}
      />
    </ConfigProvider>
  );
};

export default DateTimePicker;
